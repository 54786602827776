import { useEffect, useState, useCallback, useContext } from 'react'

import EasyCallReportLogo from '../../assets/brand/TCF_EasyCallReport_Logo_RGB.png'

import {
  CRow,
  CCol,
  CCard,
  CCardBody,
  CButton,
  CCardTitle,
  CCardText,
  CSpinner,
  CAlert,
  CImage,
  CListGroup,
  CListGroupItem,
  CCardSubtitle,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilCheckAlt,
  cilCheckCircle,
  cilWarning,
  cilX,
} from '@coreui/icons'

import { ConfigurationService } from 'src/services/ConfigurationService'
import { SubscriptionService } from 'src/services/SubscriptionService'
import { useTranslation } from "react-i18next";

import { ApplicationId } from '../../common/constants'
import { AuthContext } from 'src/components/AuthContext'
import { ChargebeeCustomer, ChargebeeSubscription, EasyCallReportConfiguration } from 'src/common/interfaces'

const MigrateECR = () => {
  const { t } = useTranslation();
  const funMessage = "Looking for snow...";
  const authContext = useContext(AuthContext);

  const subscriptionService = new SubscriptionService(authContext.accessToken);
  const configurationService = new ConfigurationService(authContext.accessToken);

  const [configuration, setConfiguration] = useState<EasyCallReportConfiguration>();
  const [isPageConfigLoading, setIsPageConfigLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loadingMsg, setLoadingMsg] = useState(funMessage);
  const [chargebeeCustomer, setChargebeeCustomer] = useState<ChargebeeCustomer>();
  const [chargebeeSubscriptions, setChargebeeSubscriptions] = useState<ChargebeeSubscription[]>([]);

  const [oldEcrModalVisible, setOldEcrModalVisible] = useState(false);

  const getConfiguration = useCallback(() => {
    setIsPageConfigLoading(true);
    setLoadingMsg("Loading...");

    configurationService.GetConfiguration().then((config) => {

      subscriptionService.getChargebeeCustomer().then((customer) => {
        const customerId = customer.id;
        setChargebeeCustomer(customer);

        subscriptionService.getChargebeeSubscriptions(customerId).then((subscriptions) => {
          setChargebeeSubscriptions(subscriptions);
        });
      });

      setConfiguration(config);
      setIsPageConfigLoading(false);

      console.log(configuration);
    }).catch((e) => {
      setErrorMsg(e.message.message);
      setIsPageConfigLoading(false);
    });

  }, []);

  useEffect(() => {
    console.log(chargebeeCustomer);
    console.log(chargebeeSubscriptions);
  }, [chargebeeCustomer, chargebeeSubscriptions])

  // On Page Load
  useEffect(() => {
    setIsPageConfigLoading(true);
    getConfiguration();
  }, []);

  function cancelChargebeeSubscription(terminate_now: boolean) {
    setLoadingMsg("Cancelling current subscription...");
    setIsPageConfigLoading(true);

    chargebeeSubscriptions.forEach(async (subscription) => {
      if(subscription.status === "active"){
        await subscriptionService.cancelChargebeeSubscription(subscription.id, !terminate_now);
      }
    });
    
  }

  return (
    <div>
      <br />
      {isPageConfigLoading ?
        <div className="d-flex justify-content-center tcf-loading-background">
          <CSpinner variant="grow" />&nbsp;&nbsp;&nbsp;<h5>{loadingMsg}</h5>
        </div> : <></>
      }
      {successMsg !== "" ?
        (<CAlert color="success" variant="solid" dismissible className="d-flex align-items-center" onClose={() => { setSuccessMsg("") }} >
          <CIcon icon={cilCheckCircle} className="flex-shrink-0 me-2" width={24} height={24} />
          <div>{successMsg}</div>
        </CAlert>)
        : <></>}

      {errorMsg !== "" ?
        (<CAlert color="danger" variant="solid" dismissible className="d-flex align-items-center" onClose={() => { setErrorMsg("") }}>
          <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
          <div>Error: {errorMsg}</div>
        </CAlert>)
        : <></>}


      <CImage fluid src={EasyCallReportLogo} alt="Easy Call Report Configuration" width={370} />
      <br />
      <br />
      <br />
      <h3>Migration</h3>
      <p>
        {t("Introduction")}
      </p>
      <br />
      <CRow>
        <CCol>
          <CCard>
            <CCardBody>
              <CCardTitle>Basic</CCardTitle>
              <br />
              <br />
              <h6 className="mb-2 text-body-secondary">Feature</h6>
              <CListGroup flush>
                <CListGroupItem><b>5</b> included Voice Apps</CListGroupItem>
                <CListGroupItem><CIcon className="text-success" icon={cilCheckAlt} /> Monitoring of Call Queues / Auto Attendants (Voice Apps)</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Easy Platform Configuration Center</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Call Notifications</CListGroupItem>
                <CListGroupItem> <CIcon className="text-danger" icon={cilX} /> Call Notes (leave text notes on cards)</CListGroupItem>
                <CListGroupItem> <CIcon className="text-danger" icon={cilX} /> Custom Cards</CListGroupItem>
                <CListGroupItem> <CIcon className="text-danger" icon={cilX} /> Custom Routing (Call information can be forwarded to third-party systems)</CListGroupItem>
              </CListGroup>
              <br />
              <h6 className="mb-2 text-body-secondary">Integration</h6>
              <CListGroup flush>
                <CListGroupItem><CIcon className="text-success" icon={cilCheckAlt} /> Search.ch Integration</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Easy Lookup Integration</CListGroupItem>
                <CListGroupItem> <CIcon className="text-danger" icon={cilX} /> Easy Directory Integration (Call queues are displayed in Easy Directory Search)</CListGroupItem>
              </CListGroup>
              <br />
              <h6 className="mb-2 text-body-secondary">Reporting</h6>
              <CListGroup flush>
                <CListGroupItem><CIcon className="text-danger" icon={cilX} /> Teams Call History Tabs (Missed/answered calls)</CListGroupItem>
              </CListGroup>
              <br />
              <CButton href="/subscriptions" color="light">
                Select Basic
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          <CCard>
            <CCardBody>
              <CCardTitle>Professional</CCardTitle>
              <CCardSubtitle className="mb-2 text-body-secondary">Recommended</CCardSubtitle>
              <br />
              <h6 className="mb-2 text-body-secondary">Feature</h6>
              <CListGroup flush>
                <CListGroupItem><b>5</b> included Voice Apps</CListGroupItem>
                <CListGroupItem><CIcon className="text-success" icon={cilCheckAlt} /> Monitoring of Call Queues / Auto Attendants (Voice Apps)</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Easy Platform Configuration Center</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Call Notifications</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Call Notes (leave text notes on cards)</CListGroupItem>
                <CListGroupItem> <CIcon className="text-danger" icon={cilX} /> Custom Cards</CListGroupItem>
                <CListGroupItem> <CIcon className="text-danger" icon={cilX} /> Custom Routing (Call information can be forwarded to third-party systems)</CListGroupItem>
              </CListGroup>
              <br />
              <h6 className="mb-2 text-body-secondary">Integration</h6>
              <CListGroup flush>
                <CListGroupItem><CIcon className="text-success" icon={cilCheckAlt} /> Search.ch Integration</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Easy Lookup Integration</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Easy Directory Integration (Call queues are displayed in Easy Directory Search)</CListGroupItem>
              </CListGroup>
              <br />
              <h6 className="mb-2 text-body-secondary">Reporting</h6>
              <CListGroup flush>
                <CListGroupItem><CIcon className="text-success" icon={cilCheckAlt} /> Teams Call History Tabs (Missed/answered calls)</CListGroupItem>
              </CListGroup>
              <br />
              <CButton href="#" color="dark" onClick={() => window.open('https://login.microsoftonline.com/common/adminconsent?client_id=' + ApplicationId)}>
                Select Professional
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          <CCard>
            <CCardBody>
              <CCardTitle>Enterprise</CCardTitle>
              <br />
              <br />
              <h6 className="mb-2 text-body-secondary">Feature</h6>
              <CListGroup flush>
                <CListGroupItem><b>5</b> included Voice Apps</CListGroupItem>
                <CListGroupItem><CIcon className="text-success" icon={cilCheckAlt} /> Monitoring of Call Queues / Auto Attendants (Voice Apps)</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Easy Platform Configuration Center</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Call Notifications</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Call Notes (leave text notes on cards)</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Custom Cards</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Custom Routing (Call information can be forwarded to third-party systems)</CListGroupItem>
              </CListGroup>
              <br />
              <h6 className="mb-2 text-body-secondary">Integration</h6>
              <CListGroup flush>
                <CListGroupItem><CIcon className="text-success" icon={cilCheckAlt} /> Search.ch Integration</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Easy Lookup Integration</CListGroupItem>
                <CListGroupItem> <CIcon className="text-success" icon={cilCheckAlt} /> Easy Directory Integration (Call queues are displayed in Easy Directory Search)</CListGroupItem>
              </CListGroup>
              <br />
              <h6 className="mb-2 text-body-secondary">Reporting</h6>
              <CListGroup flush>
                <CListGroupItem><CIcon className="text-success" icon={cilCheckAlt} /> Teams Call History Tabs (Missed/answered calls)</CListGroupItem>
              </CListGroup>
              <br />
              <CButton color="light">
                Select Enterprise
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          <CCard
            textColor="secondary"
            className="mb-3 border-secondary">
            <CCardBody>
              <CCardTitle>Keep old version</CCardTitle>
              <br />
              <br />
              <CCardText>
                You can keep Easy Call Report 1.0 including limited functionality comparing Easy Call Report 2.0<br /><br />The Easy Call Report 1.0 subscription is deprecated and won&apos;t be extended anymore. After your current subscription lifetime, your subscription will be cancelled and Easy Call Report 1.0 won&apos;t work anymore.
              </CCardText>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <CButton onClick={() => setOldEcrModalVisible(!oldEcrModalVisible)} color="danger" variant="outline" style={{ marginTop: 8 + 'px' }}>
                Keep old version
              </CButton>
            </CCardBody>
          </CCard>
          <CModal visible={oldEcrModalVisible}
            alignment="center"
            onClose={() => setOldEcrModalVisible(false)}
            aria-labelledby="Keep old version">
            <CModalHeader>
              <CModalTitle>Keep old version</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <b>Do you really want to keep Easy Call Report 1.0 and cancel the subscription until end of subscription lifetime?</b>
            </CModalBody>
            <CModalFooter>
              <CButton color="success" onClick={() => setOldEcrModalVisible(false)}>Choose another option</CButton>
              <CButton color="danger" onClick={() => cancelChargebeeSubscription(false)}>Cancel subscription</CButton>
            </CModalFooter>
          </CModal>
        </CCol>
      </CRow>
      <br />
      <br />
      <br />
    </div>
  )
}

export default MigrateECR
