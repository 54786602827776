import { Navigate, Route, Routes } from 'react-router-dom'
import { CCard, CContainer, CCardBody, CCardTitle, CCardText, CCardImage } from '@coreui/react'

// routes config
import routes from '../routes'
import { Login } from '@microsoft/mgt-react'

import TcfImg from 'src/assets/images/twincap_first_ag_cover.jpg'
import { Suspense, memo, useContext } from 'react'
import { AuthContext } from './AuthContext'
import { useTranslation } from 'react-i18next'


const AppContent = () => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  
  let body = (
    <CContainer lg>
      <Suspense>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  element={<route.element />}
                />
              )
            )
          })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  )

  return (authContext.IsTeamsAdmin === true && authContext.IsLoaded === true ? body : (
    <CContainer lg>
      <br />
      {authContext.IsLoaded === false && (
        <>
          <h2>{t("Loading")}...</h2>
        </>
      )}
      {authContext.IsLoaded === true && authContext.IsTeamsAdmin === false && (
        <CCard>
          <CCardImage orientation="top" src={TcfImg} className="tcf-card-img" />
          <CCardBody>
            <CCardTitle>{t("INVALID_PRIVILEGE_TITLE")}</CCardTitle>
            <CCardText>
              {t("INVALID_PRIVILEGE")}
              <br />
              <br />
              <h6>{t("LoggedInWithAccount")}:</h6>
            </CCardText>
            <Login></Login>
          </CCardBody>
        </CCard>
      )}
    </CContainer>))
}

export default memo(AppContent)
