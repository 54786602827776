import { useContext } from 'react'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { AppHeaderDropdown } from './header/index'
import logo from 'src/assets/images/Def_TCF_New_Logo_GW.png'
import { AppSidebarNav } from './AppSidebarNav'
import { CSidebarBrand } from '@coreui/react'
import logoNegative from 'src/assets/images/Def_TCF_New_Logo.png'
import { sygnet } from 'src/assets/brand/sygnet'
import navigation from '../_nav'
import { AuthContext } from './AuthContext'

const AppHeader = () => {
  const authContext = useContext(AuthContext);

  return (
    <CHeader position="sticky" className="mb-4 tcf-header">
      <CContainer fluid>
        <CSidebarBrand className="d-none d-md-flex" to="/">
          <img className="sidebar-brand-full" src={logoNegative} height={40} />
          <CIcon className="sidebar-brand-full" icon={logoNegative} height={100} />
          <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
        </CSidebarBrand>
        {authContext.IsTeamsAdmin ? <>
          <ul className='tcf-global-nav'>
            <AppSidebarNav items={navigation} />
          </ul>
          <CHeaderBrand className="mx-auto d-md-none" to="/">
            <CIcon icon={logo} height={48} alt="Logo" />
          </CHeaderBrand>
          <CHeaderNav className="ms-3">
            <AppHeaderDropdown />
          </CHeaderNav></> : <></>}
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
