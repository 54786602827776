import { CFooter } from '@coreui/react'
import * as React from 'react';
import { Version } from 'src/common/constants';

const AppFooter = () => {
  return (
    <CFooter>
      <div>
        <span className="ms-1">&copy; {(new Date()).getFullYear()} TwinCap First AG - Version { Version }</span>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
