import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer } from '@coreui/react'

// routes config
import routes from '../routes'
import { Login } from '@microsoft/mgt-react'

import TcfImg from 'src/assets/images/twincap_first_ag_cover.jpg'

const AnonymousAppContent = () => {
  let body = (
    <CContainer lg>
      <Suspense>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes> 
      </Suspense>
    </CContainer>
  )

  return ( body )
}

export default React.memo(AnonymousAppContent)
