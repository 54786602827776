import { createContext } from "react";
import { Subscription } from "src/common/interfaces";

export const AuthContext = createContext<{
  accessToken?: string;
  IsTeamsAdmin: boolean;
  IsLoaded: boolean;
  subscriptions?: Subscription[];
  version?: string;
}>({
  accessToken: undefined,
  IsLoaded: false,
  IsTeamsAdmin: false,
  subscriptions: [],
  version: "2.0"
});
