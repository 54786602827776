import { AppContent, AppFooter, AppHeader } from '../components/index'

import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { Scopes, RedirectUrl, APIMHostname, AdminApplicationId } from '../common/constants'
import { AuthContext } from 'src/components/AuthContext';
import { useEffect, useState } from "react";
import GraphToken from 'src/components/GraphToken';
import { SubscriptionService } from 'src/services/SubscriptionService';
import { ConfigurationService } from 'src/services/ConfigurationService';
import useIsSignedIn from 'src/components/useIsSignedIn';
import { CCard, CCardImage, CCardBody, CCardTitle, CContainer } from '@coreui/react';
import { Login } from '@microsoft/mgt-react';

import TcfImg from 'src/assets/images/twincap_first_ag_cover.jpg'

Providers.globalProvider = new Msal2Provider({
  clientId: AdminApplicationId,
  redirectUri: RedirectUrl,
  scopes: Scopes,
  customHosts: [APIMHostname]
});

const AuthenticatedLayout = () => {
  const [isSignedIn] = useIsSignedIn();
  const [accessToken] = GraphToken();
  const [subscriptions, setSubscriptions] = useState([]);
  const [IsTeamsAdmin, setIsTeamsAdmin] = useState(false);
  const [IsLoaded, setIsLoaded] = useState(false);
  const [version, setVersion] = useState("1.0");

  useEffect(() => {
    if (accessToken !== "") {
      const subscriptionService = new SubscriptionService(accessToken);
      const configurationService = new ConfigurationService(accessToken);
      
      Promise.all([
        configurationService.IsTeamsAdmin(),
        subscriptionService.getSubscriptions(),
        configurationService.GetConfiguration()
      ]).then(data => {
        setIsTeamsAdmin(data[0]);
        setSubscriptions(data[1]);
        setVersion(data[2].version);
      }).then(() => {
        setIsLoaded(true);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [accessToken]);

  return (isSignedIn ?
    <div>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AuthContext.Provider value={{ accessToken, IsTeamsAdmin, IsLoaded, subscriptions, version }}>
          <AppHeader />
          <div className="body flex-grow-1 px-3">
            <AppContent />
          </div>
          <AppFooter />
        </AuthContext.Provider>
      </div>
    </div> : 
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <br />
      <div className="body flex-grow-1 px-3">
        <CContainer lg>
          <CCard>
            <CCardImage orientation="top" src={TcfImg} className="tcf-card-img" />
            <CCardBody>
              <CCardTitle>Easy Platform Login</CCardTitle>
              <Login></Login>
            </CCardBody>
          </CCard>
        </CContainer>
      </div></div>
  )
}

export default AuthenticatedLayout
