import {
    CRow,
    CCol,
    CCard,
    CCardBody,
    CButton,
    CCardTitle,
    CCardSubtitle,
    CCardImage,
} from "@coreui/react";
import { useTranslation } from "react-i18next";

import "@coreui/coreui/dist/css/coreui.min.css";
import "./HomePageLanding.css";

const ReactImg = "../img/twincap_first_ag_cover.jpg";

const HomePageLanding = () => {
    const { t } = useTranslation();

    return (
        <CRow className="justify-content-center">
            <CCol md={4}>
                <CCard className="p-4">
                    <CCardImage orientation="top" title={t("GreetingSubscriptions")} src={ReactImg} />
                    <CCardTitle>{t("GreetingEasyPlatform")}</CCardTitle>
                    <CCardSubtitle className="mb-2 text-medium-emphasis">
                        {t("SubtitleEasyPlatform")}
                    </CCardSubtitle>
                    <CCardBody>
                        {t("DescriptionEasyPlatform")}
                        <br />
                        <br />
                        <CButton color="light" href="/">{t("ButtonEasyPlatform")}</CButton>
                    </CCardBody>
                </CCard></CCol>
            <CCol md={4}>
                <CCard className="p-4">
                    <CCardImage orientation="top" title={t("GreetingSubscriptions")} src={ReactImg} />
                    <CCardTitle>{t("GreetingSubscriptions")}</CCardTitle>
                    <CCardSubtitle className="mb-2 text-medium-emphasis">
                        {t("SubtitleSubscriptions")}
                    </CCardSubtitle>
                    <CCardBody>
                        {t("DescriptionSubscriptions")}
                        <br />
                        <br />
                        <CButton color="light" href="https://billing.twincapfirst.ch/portal/v2/login?forward=portal_main">{t("ButtonSubscriptions")}</CButton>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    );
};

export default HomePageLanding;
